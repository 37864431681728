// extracted by mini-css-extract-plugin
export var lbContainerOuter = "x_tQ";
export var lbContainerInner = "x_tR";
export var movingForwards = "x_tS";
export var movingForwardsOther = "x_tT";
export var movingBackwards = "x_tV";
export var movingBackwardsOther = "x_tW";
export var lbImage = "x_tX";
export var lbOtherImage = "x_tY";
export var prevButton = "x_tZ";
export var nextButton = "x_t0";
export var closing = "x_t1";
export var appear = "x_t2";