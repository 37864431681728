// extracted by mini-css-extract-plugin
export var carouselContainer = "y_qm d_w d_H d_bf d_Z";
export var carouselContainerCards = "y_t3 y_qm d_w d_H d_bf d_Z";
export var carouselContainerSides = "y_t4 d_w d_H d_Z";
export var prevCarouselItem = "y_t5 d_w d_H d_0 d_k";
export var prevCarouselItemL = "y_t6 y_t5 d_w d_H d_0 d_k";
export var moveInFromLeft = "y_t7";
export var prevCarouselItemR = "y_t8 y_t5 d_w d_H d_0 d_k";
export var moveInFromRight = "y_t9";
export var selectedCarouselItem = "y_vb d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "y_vc y_vb d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "y_vd y_vb d_w d_H d_Z d_bf";
export var nextCarouselItem = "y_vf d_w d_H d_0 d_k";
export var nextCarouselItemL = "y_vg y_vf d_w d_H d_0 d_k";
export var nextCarouselItemR = "y_vh y_vf d_w d_H d_0 d_k";
export var arrowContainer = "y_vj d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "y_vk y_vj d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "y_vl y_vk y_vj d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "y_vm y_vj d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "y_vn";
export var nextArrowContainerHidden = "y_vp y_vm y_vj d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "y_kG d_0";
export var prevArrow = "y_vq y_kG d_0";
export var nextArrow = "y_vr y_kG d_0";
export var carouselIndicatorContainer = "y_vs d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "y_vt d_w d_bz d_bF";
export var carouselText = "y_vv d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "y_vw y_vv d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "y_vx d_b7";
export var carouselIndicator = "y_vy y_vx d_b7";
export var carouselIndicatorSelected = "y_vz y_vx d_b7";
export var arrowsContainerTopRight = "y_vB d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "y_vC d_0 d_bl d_bC";
export var arrowsContainerSides = "y_vD d_0 d_bl d_bC";
export var smallArrowsBase = "y_vF d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "y_vG y_vF d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "y_vH y_vG y_vF d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "y_vJ y_vF d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "y_vK y_vJ y_vF d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "y_p9";
export var iconSmall = "y_vL";
export var multipleWrapper = "y_vM d_bC d_bF d_bf";
export var multipleImage = "y_vN d_bC";
export var sidesPrevContainer = "y_vP y_vG y_vF d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "y_vQ y_vG y_vF d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";